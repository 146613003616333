









































import {Component, Vue} from "vue-property-decorator";
import TextInput from "../components/form/TextInput.vue";
import PasswordInput from "../components/form/PasswordInput.vue";
import FormButton from "@/components/form/Button.vue";
import {captureException} from "@sentry/browser";

@Component({
  components: {
    TextInput,
    PasswordInput,
    FormButton,
  },
})
export default class Login extends Vue {
  public email: string = "";
  public password: string = "";
  public emailWarning = false;
  public passwordWarning = false;
  public messageVisible = false;
  public message = "";

  public login() {
    if (this.validate()) {
      const {email, password} = this;
      this.messageVisible = false;
      this.$store
        .dispatch("authRequest", {email, password})
        .then(() => this.$router.push("/downloads"))
        .catch((err) => {
          if (err.response && err.response.status == 400) {
            if (err.response.data.reason === "INVALID_CREDENTIALS") {
              this.showMessage("The entered email or password is not correct");
            } else if (err.response.data.reason === "EMAIL_NOT_VERIFIED") {
              this.showMessage(
                "Email address is not verified yet, please check your inbox to verify your email address");
            }
          } else {
            captureException(err);
            alert(err);
          }
        });
    }
  }

  public showMessage(message: string) {
    this.message = message;
    this.messageVisible = true;
  }

  public validate() {
    this.emailWarning = !this.email;
    this.passwordWarning = !this.password;

    return !(this.emailWarning || this.passwordWarning);
  }
}
