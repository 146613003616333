import axios from "axios";
import DownloadType from "../models/downloads/DownloadType";
import ChangeDownloadOrderDTO from "../models/downloads/ChangeDownloadOrderDTO";
import UploadDownloadDTO from "../models/downloads/UploadDownloadDTO";
import EditDownloadDTO from "@/models/downloads/EditDownloadDTO";
import ReplaceDownloadFileDTO from "@/models/downloads/ReplaceDownloadFileDTO";
import DeleteDownloadDTO from "@/models/downloads/DeleteDownloadDTO";

export default {
  namespaced: true,
  state: {
    protocols: {
      categories: [],
    },
    other: {
      categories: [],
    },
  },
  mutations: {
    setProtocols(state: any, {newList, category}: any) {
      const idx = state.protocols.categories.findIndex((cat: any) => cat.id === category);
      state.protocols.categories[idx].downloads = newList;
    },
    setOtherDownloads(state: any, {newList, category}: any) {
      const idx = state.protocols.categories.findIndex((cat: any) => cat.id === category);
      state.protocols.categories[idx].downloads = newList;
    },
    setProtocolCategories(state: any, list: any) {
      state.protocols.categories = list;
    },
    setOtherCategories(state: any, list: any) {
      state.other.categories = list;
    },
    addProtocol(state: any, protocol: any) {
      // tslint:disable-next-line:triple-equals
      state.protocols.categories.find((cat: any) => cat.id == protocol.categoryid).downloads.unshift(protocol);
    },
    addOtherDownload(state: any, otherDownload: any) {
      // tslint:disable-next-line:triple-equals
      state.other.categories.find((cat: any) => cat.id == otherDownload.categoryid).downloads.unshift(otherDownload);
    },
    editProtocol(state: any, protocol: any) {
      state.protocols.categories.find((cat: any) => cat.id === protocol.categoryid).downloads.splice(
        state.protocols.categories.find((cat: any) => cat.id === protocol.categoryid).downloads
          .findIndex((i: any) => i.id === protocol.id), 1, protocol);
    },
    editOtherDownload(state: any, otherDownload: any) {
      state.other.categories.find((cat: any) => cat.id === otherDownload.categoryid).downloads.splice(
        state.other.categories.find((cat: any) => cat.id === otherDownload.categoryid).downloads
          .findIndex((i: any) => i.id === otherDownload.id), 1, otherDownload);
    },
    deleteProtocol(state: any, {id, category}: any) {
      state.protocols.categories.find((cat: any) => cat.id === category).downloads.splice(
        state.protocols.categories.find((cat: any) => cat.id === category)
          .downloads.findIndex((i: any) => i.id === id), 1);
    },
    deleteOtherDownload(state: any, {id, category}: any) {
      state.other.categories.find((cat: any) => cat.id === category).downloads.splice(
        state.other.categories.find((cat: any) => cat.id === category).downloads.findIndex((i: any) => i.id === id), 1);
    },
  },
  actions: {
    fetchDownloads({commit}: any, type: DownloadType) {
      switch (type) {
        case DownloadType.Protocols:
          axios.get(`/downloads/protocols`).then((res) => {
            commit("setProtocolCategories", res.data);
          });
          break;
        case DownloadType.Other:
          axios.get(`/downloads/other`).then((res) => {
            commit("setOtherCategories", res.data);
          });
          break;
      }
    },
    changeDownloadOrder({commit}: any, dto: ChangeDownloadOrderDTO) {
      const {newList, category} = dto.orderPayload;
      const newOrders = newList.map((download: any, index: any) => {
        return {id: download.id, order: index, category_id: category};
      });

      switch (dto.downloadType) {
        case DownloadType.Protocols:
          axios.patch("/controlpanel/downloads/protocols/order", {orders: newOrders}).then(() => {
            commit("setProtocols", {
              newList: newList.map((dl: any) => {
                dl.categoryid = category;
                return dl;
              }), category,
            });
          });
          break;
        case DownloadType.Other:
          axios.patch("/controlpanel/downloads/other/order", {orders: newOrders}).then(() => {
            commit("setOtherDownloads", {
              newList: newList.map((dl: any) => {
                dl.categoryid = category;
                return dl;
              }), category,
            });
          });
      }
    },
    uploadDownload({commit}: any, dto: UploadDownloadDTO) {
      switch (dto.downloadType) {
        case DownloadType.Protocols:
          return new Promise((resolve, reject) => {
            axios.post("/controlpanel/downloads/protocols/upload", dto.formData).then((res) => {
              commit("addProtocol", res.data);
              resolve();
            }).catch(reject);
          });
        case DownloadType.Other:
          return new Promise((resolve, reject) => {
            axios.post("/controlpanel/downloads/other/upload", dto.formData).then((res) => {
              commit("addOtherDownload", res.data);
              resolve();
            }).catch(reject);
          });
      }
    },
    editDownload({commit}: any, dto: EditDownloadDTO) {
      switch (dto.downloadType) {
        case DownloadType.Protocols:
          return new Promise((resolve, reject) => {
            axios.patch("/controlpanel/downloads/protocols/" + dto.id, {name: dto.name}).then(() => {
              commit("editProtocol", {id: dto.id, categoryid: dto.categoryid, name: dto.name});
              resolve();
            }).catch(reject);
          });
        case DownloadType.Other:
          return new Promise((resolve, reject) => {
            axios.patch("/controlpanel/downloads/other/" + dto.id, {name: dto.name}).then(() => {
              commit("editOtherDownload", {id: dto.id, categoryid: dto.categoryid, name: dto.name});
              resolve();
            }).catch(reject);
          });
      }
    },
    replaceDownloadFile({commit}: any, dto: ReplaceDownloadFileDTO) {
      switch (dto.downloadType) {
        case DownloadType.Protocols:
          return new Promise((resolve, reject) => {
            axios.patch("/controlpanel/downloads/protocols/" + dto.id + "/file", dto.formData)
              .then(() => {
                commit("editProtocol", {
                  name: dto.formData.get("name"),
                  id: dto.id,
                  categoryid: dto.categoryid,
                });
                resolve();
              }).catch(reject);
          });
        case DownloadType.Other:
          return new Promise((resolve, reject) => {
            axios.patch("/controlpanel/downloads/other/" + dto.id + "/file", dto.formData)
              .then(() => {
                commit("editOtherDownload", {
                  name: dto.formData.get("name"),
                  id: dto.id,
                  categoryid: dto.categoryid,
                });
                resolve();
              }).catch(reject);
          });
      }
    },
    deleteDownload({commit}: any, dto: DeleteDownloadDTO) {
      switch (dto.downloadType) {
        case DownloadType.Protocols:
          return new Promise((resolve, reject) => {
            axios.delete("/controlpanel/downloads/protocols/" + dto.id).then(() => {
              commit("deleteProtocol", {id: dto.id, category: dto.categoryid});
              resolve();
            }).catch(reject);
          });
        case DownloadType.Other:
          return new Promise((resolve, reject) => {
            axios.delete("/controlpanel/downloads/other/" + dto.id).then(() => {
              commit("deleteOtherDownload", {id: dto.id, category: dto.categoryid});
              resolve();
            }).catch(reject);
          });
      }
    },
  },
  getters: {
    getCategory: (state: any) => (id: number, type: string) => {
      switch (type) {
        case "protocols":
          return state.protocols.categories
            .find((category: any) => category.id === id).downloads;
        case "other":
          return state.other.categories
            .find((category: any) => category.id === id).downloads;
        default:
          return null;
      }
    },
  },
};

