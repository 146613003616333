import Vue from "vue";
import Router, {Route} from "vue-router";
import {Role} from "./models/Role";
import store from "./store";

Vue.use(Router);

const isAuthenticated = (to: Route, from: Route, next: any) => {
  if (store.getters.isAuthenticated) {
    next();
  } else {
    next("/login");
  }
};

const isNotAuthenticated = (to: Route, from: Route, next: any) => {
  if (!store.getters.isAuthenticated) {
    next();
  } else {
    next("/");
  }
};

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      meta: {authorize: []},
      component: () => import(/* webpackChunkName: "notfoundpage" */ "./views/PageNotFound.vue"),
    },
    {
      path: "/",
      redirect: "/downloads",
    },
    {
      path: "/login",
      meta: {authorize: []},
      name: "login",
      beforeEnter: isNotAuthenticated,
      component: () => import(/* webpackChunkName: "login" */ "./views/Login.vue"),
    },
    {
      path: "/signup",
      meta: {authorize: []},
      name: "signup",
      beforeEnter: isNotAuthenticated,
      component: () => import(/* webpackChunkName: "signup" */ "./views/signup/Signup.vue"),
    },
    {
      path: "/password-reset",
      meta: {authorize: []},
      name: "reset-password-request",
      beforeEnter: isNotAuthenticated,
      component: () => import(/* webpackChunkName: "password-reset-request" */
        "./views/password-reset/PasswordResetRequestForm.vue"),
    },
    {
      path: "/password-reset/:hash",
      meta: {authorize: []},
      name: "reset-password-form",
      props: true,
      beforeEnter: isNotAuthenticated,
      component: () => import(/* webpackChunkName: "password-request" */
        "./views/password-reset/PasswordResetForm.vue"),
    },
    {
      path: "/signup/successful",
      meta: {authorize: []},
      name: "signup-successful",
      beforeEnter: isNotAuthenticated,
      component: () => import(/* webpackChunkName: "signup-successful" */ "./views/signup/SignupSuccessful.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      meta: {authorize: [Role.PublicUser, Role.Member, Role.BoardMember, Role.Admin]},
      beforeEnter: isAuthenticated,
      component: () => import(/* webpackChunkName: "profile" */ "./views/Profile.vue"),
    },
    {
      path: "/downloads",
      redirect: "/downloads/public",
    },
    {
      name: "public",
      path: "/downloads/public",
      meta: {authorize: []},
      beforeEnter: isAuthenticated,
      component: () => import(/* webpackChunkName: "downloads-protocols" */
        "./components/downloads/ProtocolDownloads.vue"),
    },
    {
      name: "software",
      path: "/downloads/software",
      meta: {authorize: []},
      beforeEnter: isAuthenticated,
      component: () => import(/* webpackChunkName: "downloads-software" */
        "./components/downloads/SoftwareDownloads.vue"),
    },
    {
      name: "other",
      path: "/downloads/other",
      meta: {authorize: [Role.Member, Role.BoardMember, Role.Admin]},
      beforeEnter: isAuthenticated,
      component: () => import(/* webpackChunkName: "downloads-other" */
        "./components/downloads/OtherDownloads.vue"),
    },
    {
      path: "/meetings",
      name: "meetings",
      beforeEnter: isAuthenticated,
      meta: {authorize: [Role.Member, Role.BoardMember, Role.Admin]},
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "meetings" */ "./views/Meetings.vue"),
    },
    {
      path: "/boardmeetings",
      name: "boardmeetings",
      beforeEnter: isAuthenticated,
      meta: {authorize: [Role.BoardMember, Role.Admin]},
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "boardmeetings" */ "./views/BoardMeetings.vue"),
    },
    {
      path: "/controlpanel",
      component: () => import(/* webpackChunkName: "controlpanel" */ "./views/ControlPanel.vue"),
      beforeEnter: isAuthenticated,
      meta: {authorize: [Role.Admin]},
      children: [
        {
          path: "",
          name: "controlpanel",
          redirect: "menu",
        },
        {
          path: "menu",
          name: "controlpanelmenu",
          component: () => import(/* webpackChunkName: "controlpanelmenu" */
            "./components/controlpanel/ControlPanelMenu.vue"),
        },
        {
          path: "downloads/protocols",
          name: "protocolscontrolpanel",
          component: () => import(/* webpackChunkName: "downloadscontrolpanel" */
            "./components/controlpanel/DownloadsControlPanel.vue"),
          props: {
            type: "protocols",
          },
        },
        {
          path: "downloads/software",
          name: "softwarecontrolpanel",
          component: () => import(/* webpackChunkName: "softwarecontrolpanel" */
            "./components/controlpanel/SoftwareDownloadsControlPanel.vue"),
        },
        {
          path: "downloads/other",
          name: "otherdownloadscontrolpanel",
          component: () => import(/* webpackChunkName: "downloadscontrolpanel" */
            "./components/controlpanel/DownloadsControlPanel.vue"),
          props: {
            type: "other",
          },
        },
        {
          path: "meetings",
          name: "meetingscontrolpanel",
          component: () => import(/* webpackChunkName: "meetingscontrolpanel" */
            "./components/controlpanel/MeetingsControlPanel.vue"),
        },
        {
          path: "boardmeetings",
          name: "boardmeetingscontrolpanel",
          component: () => import(/* webpackChunkName: "boardmeetingscontrolpanel" */
            "./components/controlpanel/BoardMeetingsControlPanel.vue"),
        },
        {
          path: "workshops",
          name: "workshopscontrolpanel",
          component: () => import(/* webpackChunkName: "workshopscontrolpanel" */
            "./components/controlpanel/WorkshopsControlPanel.vue"),
        },
        {
          path: "publications",
          name: "publicationscontrolpanel",
          component: () => import(/* webpackChunkName: "publicationscontrolpanel" */
            "./components/controlpanel/PublicationsControlPanel.vue"),
        },
        {
          path: "participants",
          name: "participantscontrolpanel",
          component: () => import(/* webpackChunkName: "participantscontrolpanel" */
            "./components/controlpanel/ParticipantsControlPanel.vue"),
        },
        {
          path: "newsbanners",
          name: "newsbannerscontrolpanel",
          component: () => import(/* webpackChunkName: "newsbannerscontrolpanel" */
            "./components/controlpanel/NewsBannerControlPanel.vue"),
        },
        {
          path: "users",
          name: "userscontrolpanel",
          component: () => import(/* webpackChunkName: "userscontrolpanel" */
            "./components/controlpanel/UsersControlPanel.vue"),
        },
        {
          path: "statistics/registrations",
          name: "registrationstatistics",
          component: () => import(/* webpackChunkName: "registrationstatistics" */
            "./components/controlpanel/RegistrationStatistics.vue"),
        },
        {
          path: "statistics/downloads",
          name: "downloadstatistics",
          component: () => import(/* webpackChunkName: "downloadstatistics" */
            "./components/controlpanel/DownloadStatistics.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const authorize = to.meta.authorize || to.matched.find((r) => r.meta.authorize).meta.authorize;

  if (authorize) {
    if (authorize.length && !store.getters.checkRole(authorize)) {
      return next({path: from.path});
    }
  }
  next();
});
