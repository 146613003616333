import Vue from "vue";
import Vuex from "vuex";

import downloads from "./store/downloads";
import meetings from "./store/meetings";
import auth from "./store/auth";
import boardMeetings from "./store/board-meetings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    downloads,
    meetings,
    auth,
    boardMeetings,
  },
});
