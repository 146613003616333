import axios from "axios";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    past: [],
    upcoming: [],
  },
  mutations: {
    setUpcomingMeetings(state: any, data: any) {
      state.upcoming = data || [];
    },
    setPastMeetings(state: any, data: any) {
      state.past = data || [];
    },
    addMeeting(state: any, meeting: any) {
      const meetingDate = meeting.enddate ? meeting.enddate : meeting.startdate;
      if (moment(meetingDate).isSameOrAfter(moment(), "day")) {
        state.upcoming.unshift(meeting);
      } else {
        state.past.unshift(meeting);
      }
    },
    editMeeting(state: any, meeting: any) {
      const meetingDate = meeting.enddate ? meeting.enddate : meeting.startdate;
      const isUpcoming = moment(meetingDate).isSameOrAfter(moment(), "day");
      const upcomingIdx = state.upcoming.findIndex((m: any) => m.id === meeting.id);
      const pastIdx = state.past.findIndex((m: any) => m.id === meeting.id);

      if (upcomingIdx >= 0 && isUpcoming) {
        state.upcoming[upcomingIdx] = meeting;
      } else if (upcomingIdx >= 0 && !isUpcoming) {
        state.upcoming.splice(upcomingIdx, 1);
        state.past.unshift(meeting);
      } else if (pastIdx >= 0 && isUpcoming) {
        state.past.splice(pastIdx, 1);
        state.upcoming.unshift(meeting);
      } else {
        state.past[pastIdx] = meeting;
      }
    },
    deleteMeeting(state: any, meetingId: any) {
      const upcomingIdx = state.upcoming.findIndex((m: any) => m.id === meetingId);
      if (upcomingIdx >= 0) {
        state.upcoming.splice(upcomingIdx, 1);
      } else {
        const pastIdx = state.past.findIndex((m: any) => m.id === meetingId);
        state.past.splice(pastIdx, 1);
      }
    },
    deleteFile(state: any, {fileId, meetingId}: any) {
      const upcomingIdx = state.upcoming.findIndex((m: any) => m.id === meetingId);
      if (upcomingIdx >= 0) {
        state.upcoming[upcomingIdx].files.splice(
          state.upcoming[upcomingIdx].files.findIndex((f: any) => f.id === fileId));
      } else {
        const pastIdx = state.past.findIndex((m: any) => m.id === meetingId);
        state.past[pastIdx].files.splice(
          state.past[pastIdx].files.findIndex((f: any) => f.id === fileId));
      }
    },
    addFile(state: any, {file, meetingId}: any) {
      const upcomingIdx = state.upcoming.findIndex((m: any) => m.id === meetingId);
      if (upcomingIdx >= 0) {
        state.upcoming[upcomingIdx].files.push(file);
      } else {
        const pastIdx = state.past.findIndex((m: any) => m.id === meetingId);
        state.past[pastIdx].files.push(file);
      }
    },
    sortMeetings(state: any) {
      state.upcoming.sort((a: any, b: any) => {
        const sortDateA = a.enddate ? a.enddate : a.startdate;
        const sortDateB = b.enddate ? b.enddate : b.startdate;
        return moment(sortDateA).isAfter(sortDateB) ? 1 : -1;
      });
      state.past.sort((a: any, b: any) => {
        const sortDateA = a.enddate ? a.enddate : a.startdate;
        const sortDateB = b.enddate ? b.enddate : b.startdate;
        return moment(sortDateA).isBefore(sortDateB) ? 1 : -1;
      });
    },
  },
  actions: {
    fetchMeetings({commit}: any) {
      axios.get("/meetings/upcoming").then((res) => {
        commit("setUpcomingMeetings", res.data);
        return axios.get("/meetings/past");
      }).then((res) => {
        commit("setPastMeetings", res.data);
      });
    },
    addMeeting({commit}: any, meeting: any) {
      return new Promise((resolve, reject) => {
        axios.post("/controlpanel/meetings", meeting).then((res) => {
          commit("addMeeting", res.data);
          commit("sortMeetings");
          resolve();
        }).catch(reject);
      });
    },
    editMeeting({commit}: any, meeting: any) {
      return new Promise((resolve, reject) => {
        axios.patch(`/controlpanel/meetings/${meeting.id}`, meeting).then(() => {
          commit("editMeeting", meeting);
          commit("sortMeetings");
          resolve();
        }).catch(reject);
      });
    },
    deleteMeeting({commit}: any, meetingId: any) {
      return new Promise((resolve, reject) => {
        axios.delete(`/controlpanel/meetings/${meetingId}`).then(() => {
          commit("deleteMeeting", meetingId);
          resolve();
        }).catch(reject);
      });
    },
    deleteFile({commit}: any, {file, meetingId}: any) {
      return new Promise((resolve, reject) => {
        axios.delete(`/controlpanel/meetings/${meetingId}/files/${file.id}`).then(() => {
          commit("deleteFile", {fileId: file.id, meetingId});
          resolve();
        }).catch(reject);
      });
    },
    addFile({commit}: any, {meetingId, formData}: any) {
      return new Promise(((resolve, reject) => {
        axios.post(`/controlpanel/meetings/${meetingId}/files`, formData).then((res) => {
          commit("addFile", {file: res.data, meetingId});
          resolve(res.data);
        });
      }));
    },
  },
};
