import axios from "axios";
import jwt_decode from "jwt-decode";

export default {
  state: {
    token: localStorage.getItem("token") || "",
  },
  mutations: {
    setToken(state: any, token: any) {
      state.token = token;

    },
    removeToken(state: any) {
      state.token = "";
    },
  },
  actions: {
    authRequest({commit}: any, credentials: any) {
      return axios
        .post("/auth/login", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((res) => {
          const jwt = res.data.token;
          localStorage.setItem("token", jwt);
          commit("setToken", jwt);
          axios.defaults.headers.common["x-access-token"] = jwt;
        })
        .catch((err) => {
          localStorage.removeItem("token");
          throw err;
        });
    },

    /**
     * Removes the stored token in the storage and the state.
     * Returns nothing and always succeeds.
     */
    authLogout({commit}: any) {
      commit("removeToken");
      localStorage.removeItem("token");
      delete axios.defaults.headers.common.Authorization;
    },
  },
  getters: {
    isAuthenticated: (state: any) => !!state.token,
    checkRole: (state: any) => (roles: string[]) => {
      const parsedToken: any = jwt_decode(state.token);
      const role = parsedToken.role;

      return roles.find((r) => r === role);
    },
  },
};
