










import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  props: {
    color: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
})
export default class Button extends Vue {
  @Prop()
  public color: string;

  @Prop()
  public loading: boolean;

  @Prop({default: "button"})
  public type: string;

  public click() {
    this.$emit("click");
  }

  get buttonStyle() {
    return {
      error: this.color == "error",
      confirm: this.color == "confirm",
      primary: this.color == "primary",
    };
  }
}
