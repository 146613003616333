









import Sidebar from "./components/Sidebar.vue";
import Login from "./views/Login.vue";
import {Component, Vue} from "vue-property-decorator";
import axios from "axios";

@Component({
  components: {
    Sidebar,
    Login,
  },
})
export default class App extends Vue {
  get authenticated() {
    return this.$store.getters.isAuthenticated;
  }

  public beforeCreate() {
    const vm = this;
    axios.interceptors.response.use(
      (config) => {
        return config;
      },
      (err) => {
        if (err.response && err.response.status === 401 && err.config) {
          vm.$store.dispatch("authLogout");
          this.$router.push("/login");
        }
        return Promise.reject(err);
      },
    );
  }
}

