import Vue from "vue";
import App from "./App.vue";
import {router} from "./router";
import store from "./store";
import axios from "axios";
import VModal from "vue-js-modal";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["x-access-token"] = token;
}

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || "http://localhost:3000";

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
  beforeSend: (event, hint) => {
    if (hint.originalException) {
      const err = hint.originalException as any;
      if (err.response && [400, 401, 403, 409, 422].includes(err.response.status)) {
        return null;
      }
    }
    return event;
  },
});

Vue.config.productionTip = false;
Vue.use(VModal);


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
