








import {Component, Prop, Vue} from "vue-property-decorator";


@Component({
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    warning: {
      type: Boolean,
      required: false,
    },
  },
})
export default class PasswordInput extends Vue {
  @Prop()
  public label!: string;
  @Prop()
  public value!: string;

  public change() {
    const el = this.$refs.inputVal as HTMLInputElement;
    this.$emit("input", el.value);
  }
}
