

















































import {Vue} from "vue-property-decorator";
import {Role} from "@/models/Role";

export default class Sidebar extends Vue {

  public logout = () => {
    this.$store.dispatch("authLogout").then(() => {
      this.$router.push("/login");
    });
  }

  get downloadsActive() {
    return this.$route.name === "public" || this.$route.name === "other" || this.$route.name === "software";
  }

  get canSeeMeetings() {
    return this.$store.getters.checkRole([Role.Member, Role.BoardMember, Role.Admin]);
  }

  get canSeeBoardMeetings() {
    return this.$store.getters.checkRole([Role.BoardMember, Role.Admin]);
  }

  get canSeeControlPanel() {
    return this.$store.getters.checkRole([Role.Admin]);
  }
}
