export const Role = {
  Admin: "admin",
  BoardMember: "board_member",
  Member: "member",
  PublicUser: "public_user",
};

export const displayRole = (s: string) => {
  switch (s) {
    case Role.Admin:
      return "Admin";
    case Role.BoardMember:
      return "Board Member";
    case Role.Member:
      return "Member";
    case Role.PublicUser:
      return "Public User";
    default:
      return "Unknown";
  }
};
